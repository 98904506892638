/**@jsxImportSource @emotion/react */

import React, { useMemo, useEffect, useRef, Suspense, lazy} from "react";
// import CustomDivTable from "../../components/custom-table/custom-div-table";

// import { useQuery } from "../../hooks";

import { cssSystemHistory as tempCss } from "../systems/css";
import { useTheme } from "@emotion/react";
// import moment from "moment";

import Chart from "chart.js/auto";

import { useState } from "react";

import { Bar } from "react-chartjs-2";

export default function SystemChart(props) {
    const data = props?.data
    console.log("Chart data: ", data);

  const theme = useTheme()
  const css = tempCss(theme)?.systemHistory

  const Data = useMemo(() => { return props?.data?.aggregates ?? []}, [props])

  const countTotal = (row) => {
    const defective = row?._sum?.diseased ?? 0
    const count = row?._sum?.count ?? 0

    return count - defective
  }

  const buildDataSet = (sourceData) => {
    return [
        {
            label: "Defective",
            data: sourceData.map((data) => data?._sum?.diseased ?? 0),
            backgroundColor: [
            "rgba(255,30,30,0.7)",
            ],
            borderColor: "red",
            borderWidth: 1
        },
        {
            label: "Normal",
            data: sourceData.map(countTotal),
            backgroundColor: [
            "rgba(75,192,192,0.7)",
            ],
            borderColor: "gray",
            borderWidth: 1
        },
    ]
    }

    const chartOptions = {
        plugins: {
            title: {
                display: true,
                text: "Last Session Summary"
            },
            legend: {
                display: false
            }
        },
        //parsing: false,
        //animation: false,
        responsive: true,
        //indexAxis: 'y',
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        }
    };

    const [chartData, setChartData] = useState({
            labels: Data.map((data) => data?.size ), 
            datasets: buildDataSet(Data),
        });

    useEffect(()=> {
        setChartData({
            labels: Data.map((data) => data?.size ), 
            datasets: buildDataSet(Data),
        })
        },[Data]) // empty array means it only run once

  return (
    <div css={css}>
      <div className="chart-container">
        <Bar
            data={chartData}
            options={chartOptions}
        />
      </div>
    </div>
  );
}

